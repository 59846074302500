import Component from './Component';

class Input extends Component {
    private changeListener: (e: Event) => void;
    private blurListener: () => void;
    private focusListener: () => void;

    constructor(private inputElement: HTMLInputElement) {
        super();
        this.changeListener = this.changeHandler.bind(this);
        this.blurListener = this.blurHandler.bind(this);
        this.focusListener = this.focusHandler.bind(this);
    }

    init() {
        if (document.activeElement === this.inputElement) {
            this.inputElement.closest('.input').setAttribute('data-focus', 'true');
        }

        this.inputElement.addEventListener("change", this.changeListener);
        this.inputElement.addEventListener("focus", this.focusListener);
        this.inputElement.addEventListener("blur", this.blurListener);
    }

    changeHandler(e: Event) {
        if (this.inputElement.value.length <= 0) {
            this.inputElement.closest('.input').removeAttribute('data-has-value');
            return;
        }

        this.inputElement.closest('.input').setAttribute('data-has-value', 'true');
    }

    focusHandler() {
        this.inputElement.closest('.input').setAttribute('data-focus', 'true');
    }

    blurHandler() {
        this.inputElement.closest('.input').removeAttribute('data-focus');
    }

    override cleanup() {
        this.inputElement.removeEventListener("change", this.changeListener);
        this.inputElement.removeEventListener("focus", this.focusListener);
        this.inputElement.removeEventListener("blur", this.blurListener);
    }
}

export default Input