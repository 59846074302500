import GoogleMapsApi from "./GoogleMapsApi";
import GoogleMapsStyles from "./GoogleMapsStyles";
import ScrollListener from '../../../util/ScrollListener';

interface RWDXMapLocation {
    lat: number;
    lng: number;
    title: string;
}

interface RWDXMapData {
    lat: number;
    lng: number;
    zoom: number;
    locations: RWDXMapLocation[];
}

class GoogleMaps {
    static MARKER_WIDTH = 90;
    static MARKER_HEIGHT = 116;
    private mapApi : GoogleMapsApi;
    private map : google.maps.Map;
    private markers : google.maps.Marker[] = [];
    private scrollListener : ScrollListener;
    private data : RWDXMapData;

    constructor(private mapEl : HTMLElement) {
        this.mapApi = new GoogleMapsApi("AIzaSyCkSYwpyI-wmW1LUoqt4NT0z1b_qsaaSNI");
        this.data = {
            lat: parseFloat(this.mapEl.dataset.centerLat),
            lng: parseFloat(this.mapEl.dataset.centerLng),
            zoom: parseFloat(this.mapEl.dataset.zoom),
            locations: []
        }

        const locations = parseInt(this.mapEl.dataset.locations);

        for (let i = 1; i <= locations; i++) {
            this.data.locations.push({
                lat: parseFloat(this.mapEl.getAttribute('data-location-' + i + '-lat')),
                lng: parseFloat(this.mapEl.getAttribute('data-location-' + i + '-lng')),
                title: this.mapEl.getAttribute('data-location-' + i + '-title') || 'Map'
            })
        }

        this.scrollListener = new ScrollListener();
    }

    init() {
        this.scrollListener.attachListener(this.checkBounds.bind(this));
    }

    checkBounds() {
        const bounds = this.mapEl.getBoundingClientRect();

        if (bounds.top <= window.innerHeight + window.innerHeight / 2) {
            this.mapApi.load().then(() => {
                this.renderMap()
            });

            this.scrollListener.removeListener();
            return true;
        }

        return false;
    }

    renderMap() {
        const options = {
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
            styles: GoogleMapsStyles,
            zoom: this.data.zoom,
            center: {
                lat: this.data.lat,
                lng: this.data.lng
            }
        };

        this.map = new window.google.maps.Map(this.mapEl, options);
        this.renderMarker();
    }

    renderMarker() {
        const icon = {
            url: '/img/svg/map-pin.svg',
            scaledSize: new window.google.maps.Size(GoogleMaps.MARKER_WIDTH, GoogleMaps.MARKER_HEIGHT),
            origin: new window.google.maps.Point(0,0),
            anchor: new window.google.maps.Point(GoogleMaps.MARKER_WIDTH / 2, GoogleMaps.MARKER_HEIGHT)
        }

        for (const location of this.data.locations) {
            console.log(location);
            this.markers.push(new google.maps.Marker({
                position: new google.maps.LatLng(location.lat, location.lng),
                map: this.map,
                icon,
                title: location.title,
                animation: google.maps.Animation.DROP
            }))
        }
    }
}

export default GoogleMaps;