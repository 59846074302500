export enum NotificationType {
    INFO= "info",
    ERROR = "error"
}

class RWDXNotification {
    private notificationDiv: HTMLDivElement;
    private timeout: NodeJS.Timeout;

    constructor() {
        this.notificationDiv = document.createElement('div');
        this.notificationDiv.className = "rwdx-notification";
    }
    notify(
        message: string,
        notificationType: NotificationType = NotificationType.INFO,
        notificationTime: number = 5000
    ) {
        this.notificationDiv.innerHTML = `<p>${message}</p>`;
        this.notificationDiv.setAttribute('data-type', notificationType);
        clearTimeout(this.timeout);

        document.body.appendChild(this.notificationDiv);

        this.timeout = setTimeout(() => {
            document.body.removeChild(this.notificationDiv);
        }, notificationTime);
    }
}

const rwdxNotification = new RWDXNotification();
export default rwdxNotification;