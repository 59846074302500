type CarouselMoveListeners = {
    [key: string] : (activeElements: HTMLElement[], index: number) => void;
}

const carouselMoveListeners : CarouselMoveListeners = {
    logInfo: (activeElements: HTMLElement[], index: number) => {
        console.log("active elements:", activeElements);
        console.log("index:", index);
    },
    playVideo: (activeElements: HTMLElement[], index: number) => {
        const video = activeElements[0].querySelector('video');
        video.play().catch(error => console.error(error));

        const otherVideos = document.querySelectorAll('video')

        for (const oVideo of otherVideos) {
            if (oVideo === video) continue;
            if (oVideo.paused) continue;

            oVideo.pause();
            oVideo.currentTime = 0;
        }
    }
}

export default carouselMoveListeners;