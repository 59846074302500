import Modal from '../Modal';
import Component from './components/Component';
import Input from './components/Input';
import Select from './components/Select';
import RWDXScene from './components/RWDXScene';
import RWDXCarousel from './components/carousel/RWDXCarousel';
import RWDXAccordion from './components/RWDXAccordion';
import RWDXForm from './components/RWDXForm';
import RWDXMaps from './components/maps/RWDXMaps';

type Method = "GET" | "POST";
type RWDXInsert = "modal" | "before" | "after" | "swap" | "page" | "target";
type OnInsertListener = (component: HTMLElement) => (() => void) | void;

const componentDataName = "data-rwdx-component";

// This is here instead of in its own file to avoid circular dependencies
class RWDXComponents {
    components : {
        [key: string]: Map<string, Component[]>
    } = {
        inputMap: new Map(),
        selectMap: new Map(),
        sceneMap: new Map(),
        carouselMap: new Map(),
        accordionMap:  new Map(),
        formMap: new Map(),
        mapsMap: new Map()
    };

    init(componentElement: HTMLElement = document.body) {
        this.setupInputs(componentElement);
        this.setupSelects(componentElement);
        this.setupScenes(componentElement);
        this.setupCarousels(componentElement);
        this.setupAccordions(componentElement);
        this.setupForms(componentElement);
        this.setupMaps(componentElement);
    }

    cleanupComponent(componentElement: HTMLElement) {
        const componentName = this.getComponentName(componentElement);

        for (let k in this.components) {
            let components = this.components[k].get(componentName);
            if (!components) continue;

            for (const component of components) {
                component.cleanup();
            }

            this.components[k].delete(componentName);
        }
    }

    private getComponentName(componentElement: HTMLElement = document.body) {
        if (componentElement === document.body) return "document";
        return (componentElement as HTMLElement).getAttribute('data-rwdx-component');
    }

    private setupInputs(componentElement: HTMLElement = document.body) {
        const inputElements = [].slice.call(componentElement.querySelectorAll('[data-rwdx-text-input] input, [data-rwdx-text-input] textarea'));

        const inputs = inputElements.map((inputElement: HTMLInputElement) => {
            const input = new Input(inputElement);
            input.init();
            return input;
        });

        this.components.inputMap.set(this.getComponentName(componentElement), inputs);
    }

    private setupSelects(componentElement: HTMLElement = document.body) {
        const selectElements = [].slice.call(componentElement.querySelectorAll('[data-rwdx-select]'));

        const selects = selectElements.map((selectElement: HTMLElement) => {
            const select = new Select(selectElement);
            select.init();
            return select;
        });

        this.components.selectMap.set(this.getComponentName(componentElement), selects);
    }

    private setupScenes(componentElement: HTMLElement = document.body) {
        const sceneElements = [].slice.call(document.querySelectorAll('[data-rwdx-scene], [data-rwdx-scene-default]'));
        const scenes = sceneElements.map((sceneElement: HTMLElement) => {
            return new RWDXScene(sceneElement);
        });

        this.components.sceneMap.set(this.getComponentName(componentElement), scenes);
    }

    private setupCarousels(componentElement: HTMLElement = document.body) {
        const carouselElements = [].slice.call(componentElement.querySelectorAll('[data-rwdx-carousel]'));
        const carousels = carouselElements.map((carouselElement: HTMLElement) => {
            const carousel = new RWDXCarousel(carouselElement);
            carousel.init();
            return carousel;
        });

        this.components.carouselMap.set(this.getComponentName(componentElement), carousels);
    }

    private setupAccordions(componentElement: HTMLElement = document.body) {
        const accordionElements = [].slice.call(componentElement.querySelectorAll('[data-rwdx-accordion]'));
        const accordions = accordionElements.map((accordionElement: HTMLElement) => {
            const accordion = new RWDXAccordion(accordionElement);
            accordion.init();
            return accordion;
        });

        this.components.accordionMap.set(this.getComponentName(componentElement), accordions);
    }

    private setupForms(componentElement: HTMLElement = document.body) {
        const formElements = [].slice.call(componentElement.querySelectorAll('form[data-rwdx-form]'));
        const forms = formElements.map((formElement: HTMLFormElement) => {
            const form = new RWDXForm(formElement);
            form.init();
            return form;
        });

        this.components.formMap.set(this.getComponentName(componentElement), forms);
    }

    private setupMaps(componentElement: HTMLElement = document.body) {
        const mapsElements = [].slice.call(componentElement.querySelectorAll('[data-rwdx-maps]'));
        const maps = mapsElements.map((mapElement: HTMLElement) => {
            const map = new RWDXMaps(mapElement);
            map.init();
            return map;
        });

        this.components.formMap.set(this.getComponentName(componentElement), maps);
    }
}

export const rwdxComponents = new RWDXComponents();

class RWDX {
    private swapMap = new Map<string, HTMLElement>();
    private insertListenerMap = new Map<string, OnInsertListener>();
    private cleanupMap = new Map<string, (() => void) | void>();
    private modalMap = new Map<string, Modal>();

    init() {
        document.addEventListener("click", this.clickHandler.bind(this));
    }

    private async clickHandler(e: MouseEvent) {
        const target = e.target as HTMLElement;

        if (target.hasAttribute('data-rwdx-close')) {
            this.closeComponent(target);
        }

        if (target.hasAttribute('data-rwdx-get')) {
            e.preventDefault();
            await this.prepareComponentFromClick(target, "GET");
            return;
        }

        if (target.hasAttribute('data-rwdx-post')) {
            e.preventDefault();
            await this.prepareComponentFromClick(target, "POST");
            return;
        }
    }

    private async prepareComponentFromClick(target: HTMLElement, method: Method) {
        const { dataset } = target;

        let url;
        if (method === "GET") url = dataset.rwdxGet;
        if (method === "POST") url = dataset.rwdxPost;

        const insert = dataset.rwdxInsert as RWDXInsert;

        if (insert === "before" &&
            target.previousElementSibling?.getAttribute(componentDataName) === url) return;

        if (insert === "after" &&
            target.nextElementSibling?.getAttribute(componentDataName) === url) return;

        if (insert === "swap" && target.hasAttribute('data-rwdx-swap-id')) return;

        let elementTarget = target;

        if (dataset.rwdxTarget) {
            elementTarget = document.querySelector(dataset.rwdxTarget) ?? target;
        }

        await this.openComponent(
            url,
            method,
            insert,
            this.constructRequestBody(dataset),
            elementTarget,
            target
        );
    }

    async openComponent(
        url : string,
        method: Method,
        insert: RWDXInsert = "page",
        requestBody?: any,
        elementTarget?: HTMLElement,
        originalTarget?: HTMLElement
    ) {
        let init : RequestInit = { method };

        if (method === "POST") {
            init = {
                ... init,
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(requestBody)
            }
        }

        const response = await fetch(url, init);
        const componentHtml = await response.text();
        const componentElement = document.createElement('div');

        componentElement.setAttribute(componentDataName, url);
        componentElement.innerHTML = componentHtml;

        switch (insert) {
            case "modal":
                this.modalMap.set(url, new Modal(
                    componentElement,
                    () => { this.modalMap.delete(url); },
                    true
                ));
                break;

            case "before":
                elementTarget.insertAdjacentElement("beforebegin", componentElement);
                break;

            case "after":
                elementTarget.insertAdjacentElement("afterend", componentElement);
                break;

            case "swap":
                const swapId = window.crypto.randomUUID();
                componentElement.setAttribute('data-rwdx-swap-id', swapId);
                elementTarget.insertAdjacentElement("afterend", componentElement);
                elementTarget.parentElement.removeChild(elementTarget);
                originalTarget?.setAttribute('data-rwdx-swap-id', swapId);

                this.swapMap.set(swapId, elementTarget.cloneNode(true) as HTMLElement);

                break;

            case "page":
                document.body.insertAdjacentElement("beforeend", componentElement);
                break;
        }

        if (originalTarget?.hasAttribute('data-rwdx-on-insert')) {
            const listenerName = originalTarget.dataset.rwdxOnInsert;
            const listener = this.insertListenerMap.get(listenerName);

            if (listener) {
                const cleanupId = window.crypto.randomUUID();
                const cleanup = listener(componentElement);

                componentElement.setAttribute('data-rwdx-cleanup-id', cleanupId);

                if (typeof cleanup !== "undefined") this.cleanupMap.set(cleanupId, cleanup);
            }
        }

        rwdxComponents.init(componentElement);
    }

    private constructRequestBody(dataset: DOMStringMap) : any {
        const requestBody: any = {};

        if (dataset.rwdxProps) {
            const propPairs = dataset.rwdxProps.split(";");
            for (const propPair of propPairs) {
                const keyValue = propPair.split(":");
                requestBody[keyValue[0]] = keyValue[1];
            }
        }

        return requestBody;
    }

    closeComponent(target: HTMLElement) {
        const componentElement = target.closest(`[${componentDataName}]`) as HTMLElement;
        if (!componentElement) return;

        const swapId = componentElement.getAttribute('data-rwdx-swap-id');
        const cleanupId = componentElement.getAttribute('data-rwdx-cleanup-id');

        rwdxComponents.cleanupComponent(componentElement);

        if (cleanupId) {
            const cleanup = this.cleanupMap.get(cleanupId);
            if (typeof cleanup !== "undefined") cleanup();
            this.cleanupMap.delete(cleanupId);
        }

        if (swapId) {
            componentElement.insertAdjacentElement('beforebegin', this.swapMap.get(swapId));

            // Check if the swap came from an element with rwdx-target attribute set
            const originalTarget = document.querySelector(`[data-rwdx-swap-id="${swapId}"]`);
            originalTarget?.removeAttribute('data-rwdx-swap-id');

            this.swapMap.delete(swapId);
        }

        for (const [name, modal] of this.modalMap) {
            if (name === componentElement.dataset.rwdxComponent) {
                modal.closeModal();
                return;
            }
        }

        componentElement.parentElement.removeChild(componentElement);
    }

    addInsertListener(name: string, insertListener: OnInsertListener) {
        this.insertListenerMap.set(name, insertListener);
    }
}

const rwdx = new RWDX();
export default rwdx;