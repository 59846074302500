const heroScrollScripts = () => {
    const scrollTimeout : NodeJS.Timeout = null;
    let checkFrame : number = null;

    const scrollBelowFold = () => {
        const heroBandHeight = document.querySelector('.hero').scrollHeight;

        window.scrollTo({
            top: heroBandHeight,
            left: 0,
            behavior: "smooth"
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0";
            document.querySelector('nav').style.paddingRight = "0";
        }, 50);

        cancelAnimationFrame(checkFrame);
        window.removeEventListener("wheel", scrollHandler);
        window.removeEventListener("keydown", scrollHandler);
        checkFrame = window.requestAnimationFrame(checkPosition);
    }

    const scrollHandler = (e: WheelEvent | KeyboardEvent) => {
        if (e instanceof WheelEvent && e.deltaY > 0 ||
            e instanceof KeyboardEvent && e.code === "ArrowDown") {
            if (e instanceof KeyboardEvent) {
                e.preventDefault();
                scrollBelowFold();
                return;
            }

            clearTimeout(scrollTimeout);
            setTimeout(() => {
                const heroBounds = document.querySelector('.hero')
                    .getBoundingClientRect();

                if (heroBounds.bottom <= 0) return;
                scrollBelowFold()
            }, 100);
        }
    }

    const checkPosition = () => {
        checkFrame = window.requestAnimationFrame(checkPosition);

        if (window.innerWidth <= 980) return;

        const heroBand = document.querySelector('.hero');
        const heroBounds = heroBand.getBoundingClientRect();

        if (heroBounds.top !== 0) return;

        document.body.style.overflow = "hidden";

        if (window.innerWidth >= 1200) {
            document.body.style.paddingRight = "17px";
            document.querySelector('nav').style.paddingRight = "17px";
        }

        window.addEventListener("wheel", scrollHandler);
        window.addEventListener("keydown", scrollHandler);
        cancelAnimationFrame(checkFrame);
    }

    checkFrame = window.requestAnimationFrame(checkPosition);
}

export default heroScrollScripts;