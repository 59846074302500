import FocusTrap from '../../util/FocusTrap';

class Select {
    private inputElement: HTMLInputElement;
    private valueButton: HTMLElement;
    private dropDownElement: HTMLElement;
    private options: HTMLElement[];
    private focusTrap: FocusTrap;
    private open: boolean;
    private openListener: () => void;
    private changeListener: (e: PointerEvent) => void;

    constructor(private selectElement: HTMLElement) {
        this.open = false;
        this.openListener = () => { this.toggleOpen() };
        this.changeListener = this.changeHandler.bind(this);

        this.inputElement = selectElement.querySelector('input');
        this.valueButton = selectElement.querySelector('.select__value');
        this.dropDownElement = selectElement.querySelector('.select__dropdown');
        this.options = [].slice.call(selectElement.querySelectorAll('button[data-value]'));
    }

    init() {
        this.valueButton.addEventListener('click', this.openListener);

        this.focusTrap = new FocusTrap(this.selectElement, {
            activateImmediately: false,
            allowArrowKeys: true,
            onDeactivate: () => this.toggleOpen(false)
        });
    }

    private toggleOpen(override?: boolean) {
        this.open = override ?? !this.open;

        if (this.open) {
            for (const option of this.options) {
                option.tabIndex = 1;
                option.addEventListener("click", this.changeListener);
            }

            if (!this.focusTrap.active) this.focusTrap.activate();
        } else {
            for (const option of this.options) {
                option.tabIndex = -1;
                option.removeEventListener("click", this.changeListener)
            }

            if (this.focusTrap.active) this.focusTrap.deactivate(true)
        }

        this.selectElement.setAttribute('aria-expanded', this.open.toString());
        this.dropDownElement.setAttribute('aria-hidden', (!this.open).toString());
    }

    private changeHandler(e: PointerEvent) {
        e.stopPropagation();
        const currentTarget = e.currentTarget as HTMLElement;
        const span = this.valueButton.firstElementChild as HTMLSpanElement;

        this.inputElement.value = currentTarget.getAttribute('data-value');
        span.innerText = currentTarget.innerText;

        this.focusTrap.deactivate();
    }

    cleanup() {
        this.valueButton.removeEventListener("click", this.openListener);
        for (const option of this.options) {
            option.removeEventListener("click", this.changeListener);
            option.removeEventListener("keydown", this.changeListener);
        }
    }
}

export default Select;