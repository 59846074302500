import getSpinnerHtml from './util/getSpinnerHtml';
import RWDXNotification from './rwdx/components/RWDXNotification';
import ScrollListener from './util/ScrollListener';

const blogScripts = () => {
    let page = 2;
    let stopLoading = false;
    let busy = false;
    const blogThumbsDiv = document.getElementById('blog_thumbs');
    const loadMoreScrollEl : HTMLDivElement = document.querySelector('[data-rwdx-blog-load-more-scroll]');
    const loadMoreClickEl : HTMLButtonElement = document.querySelector('[data-rwdx-blog-load-more]');
    const loadingDiv : HTMLDivElement = document.createElement('div');
    const scrollListener = new ScrollListener();

    loadingDiv.className = 'loading-container';
    loadingDiv.innerHTML = getSpinnerHtml();

    const loadMore = () => {
        if (stopLoading || busy) return;
        busy = true;

        if (!document.body.contains(loadingDiv)) document.body.appendChild(loadingDiv);

        fetch('/blogScripts.php', {
            method: 'POST',
            body: JSON.stringify({
                action: 'ACTION_LOAD_MORE',
                page
            })
        })
            .then(response => response.text())
            .then(html => {
                busy = false;

                if (document.body.contains(loadingDiv)) document.body.removeChild(loadingDiv);

                if (html === '<p class="error">There are currently no blog posts</p>') {
                    stopLoading = true;
                    if (loadMoreClickEl) loadMoreClickEl.style.display = "none";
                    RWDXNotification.notify('There are no more blog posts to show!');
                    return;
                }

                page++;
                blogThumbsDiv.innerHTML += html.toString();
            })
            .catch(error => {
                console.log(error);
                if (document.body.contains(loadingDiv)) document.body.removeChild(loadingDiv);
                RWDXNotification.notify('A network error occurred when loading blog posts.');
                scrollListener.removeListener();
            })
    }

    const listener = () => {
        if (stopLoading || busy) return;

        const triggerBounds = loadMoreScrollEl.getBoundingClientRect();

        if (triggerBounds.top <= window.innerHeight - 150) {
            loadMore()
        }
    }

    if (loadMoreScrollEl) {
        scrollListener.attachListener(listener)
    }

    loadMoreClickEl?.addEventListener("click", loadMore);
}

export default blogScripts;
