export interface FocusTrapOptions {
    activateImmediately?: boolean;
    initialFocusElement?: HTMLElement;
    allowArrowKeys?: boolean;
    onDeactivate?: () => void;
}
class FocusTrap {
    active: boolean;
    private returnFocus?: HTMLElement;
    private readonly keyboardListener;
    private readonly mouseDownListener;

    constructor(
        private containerElement: HTMLElement,
        private options: FocusTrapOptions,
        private currentFocus: number = 0
    ) {
        this.active = false;
        this.keyboardListener = this.keyboardHandler.bind(this);
        this.mouseDownListener = this.mouseDownHandler.bind(this);
        if (options.activateImmediately) this.activate();
    }

    activate() {
        this.returnFocus = document.activeElement as HTMLElement;

        this.containerElement.setAttribute('data-focus-trap-active', 'true');
        this.focusEventListener(1, this.options.initialFocusElement);

        window.addEventListener('keydown', this.keyboardListener);
        window.addEventListener('mousedown', this.mouseDownListener);

        this.active = true;
        this.currentFocus = 0;
    }

    deactivate(ignoreEvent?: boolean) {
        this.containerElement.removeAttribute('data-focus-trap-active');

        window.removeEventListener('keydown', this.keyboardListener);
        window.removeEventListener('mousedown', this.mouseDownListener);

        this.active = false;
        this.returnFocus?.focus();

        if (!ignoreEvent) {
            this.options.onDeactivate && this.options.onDeactivate();
        }
    }

    private checkCanFocus(element: HTMLElement) {
        const tagRegEx = /^(A|INPUT|TEXTAREA|BUTTON|LABEL)$/

        if (element.tagName === "INPUT" &&
            (element as HTMLInputElement).type === "hidden") return false;

        if (element.tabIndex <= -1) return false;

        return !isNaN(element.tabIndex) ||
            element.tagName.match(tagRegEx)
    }

    private findFocusable() : HTMLElement[] {
        const focusable: HTMLElement[] = [];

        let nextChild = this.containerElement.firstElementChild;
        while(nextChild !== null) {
            if (this.checkCanFocus(nextChild as HTMLElement))
                focusable.push(nextChild as HTMLElement);

            nextChild = nextChild.firstElementChild ??
                nextChild.nextElementSibling ??
                nextChild.parentElement.nextElementSibling;
        }

        return focusable;
    }

    private focusEventListener(direction: 1|-1 = 1, focusElement?: HTMLElement) {
        const focusableElements = this.findFocusable();

        if (this.containerElement.contains(this.containerElement.querySelector('[data-focus-trap-active]'))) {
            return;
        }

        if (this.containerElement.contains(focusElement)) {
            focusElement.focus();
            return;
        }

        if (!this.containerElement.contains(document.activeElement)) {
            focusableElements[0]?.focus();
            return;
        }

        this.currentFocus += direction;
        if (this.currentFocus < 0) this.currentFocus = focusableElements.length - 1;
        if (this.currentFocus > focusableElements.length - 1) this.currentFocus = 0;

        focusableElements[this.currentFocus].focus();
    }

    private keyboardHandler(e: KeyboardEvent) {
        if (this.containerElement.contains(
            this.containerElement.querySelector('[data-focus-trap-active]'))
        ) {
            return;
        }

        switch (e.key) {
            case "Escape":
                if (this.containerElement.contains(document.activeElement))
                    return this.deactivate();
                break;

            case "Tab":
                e.preventDefault();
                if (e.shiftKey) return this.focusEventListener(-1);
                return this.focusEventListener();

            case "ArrowUp":
                if (this.options.allowArrowKeys) {
                    e.preventDefault();
                    return this.focusEventListener(-1);
                }
                break;

            case "ArrowDown":
                if (this.options.allowArrowKeys) {
                    e.preventDefault();
                    return this.focusEventListener(1);
                }
                break;
        }
    }

    private mouseDownHandler(e: MouseEvent) {
        if (this.containerElement.contains(
            this.containerElement.querySelector('[data-focus-trap-active]'))
        ) {
            return;
        }

        const target = e.target as HTMLElement;
        if (!this.containerElement.contains(target)) {
            this.deactivate();
        }
    }
}

export default FocusTrap;