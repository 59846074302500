type GTMConsent = "denied" | "granted";
export type GTMConsentTagName = "ad_storage" | "analytics_storage";

export type GTMConsentData = {
    [tagName in GTMConsentTagName]?: GTMConsent;
};

class GoogleTagManager {
    private idleTime = 5000;
    private initialised = false;
    private idleTimeout: NodeJS.Timeout;
    private eventListener: (e: Event) => void;
    constructor() {
        this.eventListener = this.eventHandler.bind(this);

        window.addEventListener("scroll", this.eventListener);
        window.addEventListener("mousemove", this.eventListener);
        window.addEventListener("touchstart", this.eventListener);

        this.idleTimeout = setTimeout(() => {
            this.init();
        }, this.idleTime);
    }

    eventHandler(e: Event) {
        clearTimeout(this.idleTimeout);
        if (!this.initialised) this.init();
        e.currentTarget.removeEventListener(e.type, this.eventListener);
    }

    private init() {
        if (this.initialised) return;

        const scriptTag = document.createElement('script');
        scriptTag.type = "text/javascript";
        scriptTag.async = true;
        scriptTag.addEventListener("load", this.sendPageView.bind(this));
        scriptTag.src = `https://www.googletagmanager.com/gtm.js?id=${"GTM-M3LHBFB"}`;

        document.head.appendChild(scriptTag);
    }

    getLocalData() : GTMConsentData {
        const storage = localStorage.getItem("RWD_GOOGLE_TAGS");
        return storage ? JSON.parse(storage) : null;
    }

    gtag(...args : any[]) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(args);
    }

    pushToDataLayer(data : { [eventName: string]: any }) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(data);
    }

    sendPageView() {
        const defaultConsent : GTMConsentData = this.getLocalData() ?? {
            "analytics_storage": "denied",
            "ad_storage": "denied"
        };

        this.gtag("consent", "default", defaultConsent);
        localStorage.setItem("RWD_GOOGLE_TAGS", JSON.stringify(defaultConsent));

        this.pushToDataLayer({
            "event": "default_consent"
        });

        this.pushToDataLayer({
            "gtm.start": (new Date()).getTime(),
            "event": "gtm.js"
        })
    }

    denyConsent(tagName : GTMConsentTagName) {
        this.gtag("consent", "update", {
            [tagName] : "denied"
        });

        const data : GTMConsentData = this.getLocalData() ?? {};
        data[tagName] = "denied";

        localStorage.setItem(
            "RWD_GOOGLE_TAGS",
            JSON.stringify(data)
        )
    }

    grantConsent(tagName: GTMConsentTagName) {
        this.gtag("consent", "update", {
            [tagName]: "granted"
        });

        const data : GTMConsentData = this.getLocalData() ?? {};
        data[tagName] = "granted";

        localStorage.setItem(
            "RWD_GOOGLE_TAGS",
            JSON.stringify(data)
        )
    }

    consentAll() {
        this.grantConsent("ad_storage");
        this.grantConsent("analytics_storage");
        this.setPromptSeen();
    }

    setPromptSeen() {
        localStorage.setItem(
            "RWD_COOKIES_PROMPT",
            "seen"
        )

        localStorage.setItem(
            "RWD_COOKIES_TIMEOUT",
            (new Date()).getTime().toString()
        );
    }
}

const googleTagManager = new GoogleTagManager();
export default googleTagManager;