import ScrollListener from '../../util/ScrollListener';

class RWDXScene {
    private scrollListener: ScrollListener;
    private scrollOffset: number;
    constructor(private sceneElement: HTMLElement) {
        this.scrollOffset = parseInt(sceneElement.getAttribute('data-rwdx-scene-offset') ?? "0");
        this.scrollListener = new ScrollListener();
        this.scrollListener.attachListener(this.listen.bind(this));
    }

    private listen() {
        const bounds = this.sceneElement.getBoundingClientRect();
        if (bounds.top > window.innerHeight * 0.9 + this.scrollOffset) return;

        this.sceneElement.setAttribute('data-rwdx-scene-visible', 'true');
        this.scrollListener?.removeListener();
    }

    cleanup() {
        this.scrollListener?.removeListener();
    }
}

export default RWDXScene;