import FocusTrap from './util/FocusTrap';

class Modal {
    open: boolean;
    containerElement: HTMLDivElement;
    windowElement: HTMLDivElement;
    private readonly closeButton: HTMLButtonElement;
    private focusTrap: FocusTrap;
    private exitTimeout: NodeJS.Timeout;
    private closeClickListener: (e: MouseEvent) => void;

    constructor(private content: string | HTMLElement, private onClose: () => void, private openImmediately = true) {
        this.exitTimeout = null;
        this.closeClickListener = this.closeClickHandler.bind(this);
        this.containerElement = document.createElement('div');
        this.windowElement = document.createElement('div');
        this.closeButton = document.createElement('button');

        this.containerElement.className = "modal column align-center thin-scroll-bar";
        this.windowElement.className = "modal__window";
        this.closeButton.className = "modal__close ui-hover ui-hover--light";

        if (typeof this.content === "string") {
            this.windowElement.innerHTML = this.content;
        } else {
            this.windowElement.appendChild(this.content as HTMLElement);
        }

        this.windowElement.appendChild(this.closeButton);
        this.containerElement.appendChild(this.windowElement);

        const initialFocusElement : HTMLElement = this.windowElement.querySelector('[data-rwdx-initial-focus]');

        this.focusTrap = new FocusTrap(this.windowElement, {
            activateImmediately: false,
            onDeactivate: this.closeModal.bind(this),
            initialFocusElement
        });

        if (this.openImmediately) this.openModal();
    }

    openModal() {
        clearTimeout(this.exitTimeout);
        document.body.appendChild(this.containerElement);
        if (!this.focusTrap.active) this.focusTrap.activate();
        this.containerElement.setAttribute('aria-hidden', 'false');
        this.containerElement.addEventListener("click", this.closeClickListener)
        this.open = true;
        document.body.setAttribute('data-scroll-lock', 'true');
    }

    closeClickHandler(e: MouseEvent) {
        const target = e.target as HTMLElement;
        if (target.hasAttribute('data-rwdx-close') || target === this.closeButton)
            this.closeModal();
    }

    closeModal() {
        this.containerElement.setAttribute('aria-hidden', 'true');
        document.body.removeAttribute('data-scroll-lock');

        clearTimeout(this.exitTimeout);
        this.exitTimeout = setTimeout(() => {
            document.body.removeChild(this.containerElement);
            this.onClose();
            this.open = false;
        }, 500);

        if (this.focusTrap.active) {
            this.focusTrap.deactivate(true);
        }

        this.containerElement.removeEventListener("click", this.closeClickListener)
    }
}

export default Modal;