import {WidgetInstance} from 'friendly-challenge';
import RWDX from '../RWDX';
import GoogleTagManager from "../../googleTagManager/GoogleTagManager";
import Component from './Component';

class RWDXForm extends Component {
    inputElList: NodeListOf<HTMLInputElement>;
    selectElList: NodeListOf<HTMLSelectElement>;
    textareaElList: NodeListOf<HTMLTextAreaElement>;
    widget: WidgetInstance;
    sending: boolean;
    submitListener: (e: SubmitEvent) => void;

    constructor(private formEl: HTMLFormElement) {
        super();
        this.submitListener = this.submitHandler.bind(this);
    }

    init() {
        this.formEl.addEventListener("submit", this.submitListener);
        const captchaEl = document.createElement("div");
        const buttonEl = this.formEl.querySelector('button[type="submit"]');

        captchaEl.className = "captcha";
        buttonEl.insertAdjacentElement("beforebegin", captchaEl);

        this.widget = new WidgetInstance(captchaEl, {
            sitekey: "FCMHKULDAKINH3RP"
        });
    }

    errorHandler(error : any) {
        this.sending = false;
        this.formEl.parentElement.removeAttribute('data-loading');
        this.formEl.querySelector('.captcha').insertAdjacentHTML('beforebegin',
            `<p class="error">${error}</p>`);
    }

    async submitHandler(e: SubmitEvent) {
        e.preventDefault();
        if (this.sending) return;

        this.inputElList = this.formEl.querySelectorAll('input') as NodeListOf<HTMLInputElement>;
        this.selectElList = this.formEl.querySelectorAll('select') as NodeListOf<HTMLSelectElement>;
        this.textareaElList = this.formEl.querySelectorAll('textarea') as NodeListOf<HTMLTextAreaElement>;

        const jsonData = this.getJsonData();
        this.sending = true;

        const errorP = this.formEl.querySelector('.error');
        errorP?.remove();

        this.formEl.parentElement.setAttribute('data-loading', 'true');

        fetch("/contact.php", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(jsonData)
        })
            .then(async (response) => {
                if (!response.ok) {
                    return this.errorHandler(await response.text());
                }

                RWDX.closeComponent(this.formEl);
                await RWDX.openComponent(
                    "/rwdx/components/messageSent.php",
                    "GET",
                    "modal"
                );

                this.sending = false;
                this.formEl.parentElement.removeAttribute('data-loading');

                GoogleTagManager.pushToDataLayer({
                    'event': 'enquiry_form'
                })
            })
            .catch(this.errorHandler.bind(this));
    }

    getJsonData() {
        const data: any = {};

        for (const input of this.inputElList) {
            data[input.name] = input.value;
        }

        for (const select of this.selectElList) {
            data[select.name] = select.value;
        }

        for (const textarea of this.textareaElList) {
            data[textarea.name] = textarea.value;
        }

        return data;
    }

    override cleanup() {
        this.widget.destroy();
        this.formEl.removeEventListener("submit", this.submitListener);
    }
}

export default RWDXForm;