class GoogleMapsApi {
    private loaded : boolean;
    private callbackName : string;
    private promise : Promise<any>;
    private resolve : any;

    constructor(private apiKey: string) {
        this.loaded = false;

        if (!window._GoogleMapsApi) {
            this.callbackName = '_GoogleMapsApi.mapLoaded';
            window._GoogleMapsApi = this;
            window._GoogleMapsApi.mapLoaded = this.mapLoaded.bind(this);
        }
    }

    load() {
        if (!this.promise) {
            this.promise = new Promise(resolve => {
                this.resolve = resolve;

                if (!this.loaded) {
                    const script = document.createElement('script');
                    script.src = `//maps.googleapis.com/maps/api/js?key=${this.apiKey}&callback=${this.callbackName}`;
                    script.async = true;
                    document.body.append(script);
                    this.loaded = true;
                } else {
                    this.resolve();
                }
            })
        }

        return this.promise;
    }

    mapLoaded() {
        if (this.resolve) {
            this.resolve();
        }
    }
}

export default GoogleMapsApi;
