import RWDX from './rwdx/RWDX';
import GoogleTagManager, {
    GTMConsentData,
    GTMConsentTagName
} from './googleTagManager/GoogleTagManager';

const cookies = () => {
    const cookiesSeen = localStorage.getItem("RWD_COOKIES_PROMPT");
    const cookiesTimeout = localStorage.getItem("RWD_COOKIES_TIMEOUT");

    if (cookiesSeen) {
        const timeout = parseInt(cookiesTimeout) + 2629800000;
        const now = (new Date()).getTime();

        if (now >= timeout) {
            localStorage.removeItem("RWD_COOKIES_PROMPT");
            localStorage.removeItem("RWD_COOKIES_TIMEOUT");
        } else return
    }

    RWDX.openComponent("/rwdx/components/cookiesBanner.php", "GET")
        .then(() => {
            const acceptButton = document.getElementById('accept-cookies');

            acceptButton?.addEventListener("click", () => {
                GoogleTagManager.consentAll();
            });
        })
}

RWDX.addInsertListener("cookiesPreferences", (component: HTMLElement) => {
    const consentData : GTMConsentData = GoogleTagManager.getLocalData();
    const acceptButton = document.getElementById('accept-all');
    const saveButton = document.getElementById('save-preferences');

    for (const k in consentData) {
        const input = document.querySelector(`input[name="${k}"]`) as HTMLInputElement;
        if (consentData[k as GTMConsentTagName] === "granted") input.checked = true;
    }

    acceptButton?.addEventListener("click", () => {
        GoogleTagManager.consentAll();
    });

    saveButton?.addEventListener("click", () => {
        const inputs = component.querySelectorAll('input[type="checkbox"]') as NodeListOf<HTMLInputElement>;

        for (const input of inputs) {
            if (input.checked) GoogleTagManager.grantConsent(input.name as GTMConsentTagName);
            else GoogleTagManager.denyConsent(input.name as GTMConsentTagName);
        }

        GoogleTagManager.setPromptSeen();
        RWDX.closeComponent(saveButton);
    });
});

export default cookies;