export type SlideType = "up" | "down";

const slideHeight = (element: HTMLElement, slideType?: SlideType) => {
    let maxHeight = 0;

    for (let i = 0; i < element.children.length; i++) {
        const computed = getComputedStyle(element.children[i]);
        maxHeight += element.children[i].scrollHeight;
        maxHeight += parseFloat(computed.marginTop);
        maxHeight += parseFloat(computed.marginBottom);
    }

    let speed = maxHeight / 10;

    const slideToHeight = () => {
        if (element.offsetHeight > (maxHeight)) return;
        if (element.offsetHeight + speed > maxHeight)
            speed = maxHeight - element.offsetHeight;
        element.style.height = (element.offsetHeight + speed) + "px";
        requestAnimationFrame(slideToHeight);
    }

    const slideToZero = () => {
        if (element.offsetHeight < 0) return;
        if (element.offsetHeight < speed) speed = element.offsetHeight;
        element.style.height = (element.offsetHeight - speed) + "px";
        requestAnimationFrame(slideToZero);
    }

    if (slideType && slideType === "up") {
        slideToZero();
        return;
    }

    if (slideType && slideType === "down") {
        slideToHeight();
        return;
    }

    if (element.offsetHeight === 0) {
        slideToHeight();
        return;
    }

    slideToZero();
}

export default slideHeight;