class ScrollListener {
    private listener: (scrollElement: HTMLElement | Window) => void;
    private scrollTimeout: NodeJS.Timeout;
    private listenerFrame: number;
    private scrollListener: () => void;

    constructor(private scrollElement: HTMLElement | Window = window) {
        this.scrollListener = this.scrollHandler.bind(this);
    }

    private scrollHandler() {
        clearTimeout(this.scrollTimeout)

        if (!this.listenerFrame) {
            this.listenerFrame = requestAnimationFrame(this.listenerFrameHandler.bind(this));
        }

        this.scrollTimeout = setTimeout(() => {
            cancelAnimationFrame(this.listenerFrame);
            this.listenerFrame = null;
        }, 200);
    }

    private listenerFrameHandler() {
        this.listenerFrame = requestAnimationFrame(this.listenerFrameHandler.bind(this));
        this.listener(this.scrollElement);
    }

    attachListener(listener: (scrollElement: HTMLElement | Window) => void) {
        this.listener = listener;
        this.scrollElement.addEventListener("scroll", this.scrollListener);
        this.listener(this.scrollElement);
    }

    removeListener() {
        cancelAnimationFrame(this.listenerFrame);
        clearTimeout(this.scrollTimeout);
        this.scrollElement.removeEventListener("scroll", this.scrollListener);
    }
}

export default ScrollListener;