'use strict';

import RWDX, {rwdxComponents} from './rwdx/RWDX';
import cookies from './cookies';
import processAnimation from './processAnimation';
import blogScripts from './blogScripts';
import textAnimation from "./textAnimation";
import heroScrollScripts from './heroScrollScripts';

rwdxComponents.init();
RWDX.init();

cookies();

if (document.getElementById('process-animation')) {
    processAnimation();
}

if (document.getElementById('blog-page')) {
    blogScripts();
}

if(document.getElementById('highlighted-word')) {
    textAnimation()
}

const navButton = document.querySelector('button[data-open-nav]');

navButton?.addEventListener("click", () => {
    document.body.setAttribute(
        'data-nav-open',
        (document.body.dataset.navOpen !== "true").toString()
    )
});

if (document.querySelector('.hero')) {
    heroScrollScripts();
}