import ScrollListener from './util/ScrollListener';

const processAnimation = () => {
    const processAnimationEl = document.getElementById('process-animation');

    const listener = () => {
        const bounds = processAnimationEl.getBoundingClientRect();
        const vanishPoint = window.innerHeight * 0.6;
        if (bounds.top > vanishPoint) return;

        const stage = parseInt(processAnimationEl.dataset.animationStage);

        if (stage === 0) {
            processAnimationEl.setAttribute('data-animation-stage', '1');
            return;
        }

        if (bounds.top < vanishPoint - 800) {
            if (stage >= 3) return;

            processAnimationEl.setAttribute('data-animation-stage', '3');
            return;
        }

        if (bounds.top < vanishPoint - 400) {
            if (stage >= 2) return;

            processAnimationEl.setAttribute('data-animation-stage', '2');
            return;
        }
    }

    const scrollListener = new ScrollListener();
    scrollListener.attachListener(listener)
}

export default processAnimation;