import slideHeight from '../../util/slideHeight';
import Component from './Component';

class RWDXAccordion extends Component {
    itemElements: HTMLElement[];
    clickListener: (e: PointerEvent) => void;

    constructor(private containerElement: HTMLElement) {
        super();
        this.itemElements = [].slice.call(
            containerElement.querySelectorAll('[data-rwdx-accordion-item]')
        ) as HTMLElement[];

        this.clickListener = this.clickHandler.bind(this);
    }

    init() {
        for (const itemElement of this.itemElements) {
            itemElement.addEventListener("click", this.clickListener);
        }
    }

    private clickHandler(e: PointerEvent) {
        const currentTarget = e.currentTarget as HTMLElement;
        const target = e.target as HTMLElement;

        if (target.hasAttribute('data-rwdx-accordion-head')) {
            if (currentTarget.hasAttribute('aria-expanded')) {
                this.close(currentTarget);
                return;
            }

            this.open(currentTarget);
        }
    }

    private open(selectedElement: HTMLElement) {
        const body = selectedElement.querySelector('[data-rwdx-accordion-body]') as HTMLElement;
        selectedElement.setAttribute('aria-expanded', 'true');

        slideHeight(body, "down");

        for (const itemElement of this.itemElements) {
            if (itemElement === selectedElement) continue;
            if (itemElement.hasAttribute('aria-expanded')) {
                this.close(itemElement);
            }
        }
    }

    private close(selectedElement: HTMLElement) {
        const body = selectedElement.querySelector('[data-rwdx-accordion-body]') as HTMLElement;
        selectedElement.removeAttribute('aria-expanded');
        slideHeight(body, "up");
    }

    override cleanup() {
        for (const itemElement of this.itemElements) {
            itemElement.removeEventListener("click", this.clickListener);
        }
    }
}

export default RWDXAccordion;