import getRootFontSize from '../../../util/getRootFontSize';
import carouselMoveListeners from "./carouselMoveListeners";
import Component from '../Component';

interface CarouselBreakpoint {
    breakpoint: number;
    visible?: number;
    move?: number;
}
interface CarouselOptions {
    speed?: number;
    auto?: boolean;
    autoPause?: number;
    visible?: number;
    visibleBreakPoints?: CarouselBreakpoint[];
    move?: number;
    moveBreakpoints?: CarouselBreakpoint[];
    gap?: number;
    onMove?: (activeElements: HTMLElement[], index: number) => void;
}

class RWDXCarousel extends Component {
    private hasInit: boolean;
    private index: number;
    private visibleElements: HTMLElement[];
    private viewportElement: HTMLElement;
    private controlsElement: HTMLElement;
    private pagerElement: HTMLElement;
    private options: CarouselOptions;
    private moveTimeout: NodeJS.Timeout;
    private resizeTimeout: NodeJS.Timeout;
    private autoInterval: NodeJS.Timeout;
    private itemSize: number;
    private visible: number;
    private hasClones: boolean;
    private moving: boolean;
    private touchStartX?: number | null;
    private touchMoveX?: number | null;
    private touchFrame?: number | null;
    private touchStartListener: (e: TouchEvent) => void;
    private touchEndListener: () => void;
    private touchMoveListener: (e: TouchEvent) => void;
    private keyListener: (e: KeyboardEvent) => void;
    private resizeListener: () => void;

    constructor(private railElement: HTMLElement) {
        super();

        if (!this.railElement) {
            throw new Error("Element not found");
        }

        this.index = 0;
        this.itemSize = 0;
        this.hasClones = false;
        this.moveTimeout = null;
        this.visibleElements = [];
        this.viewportElement = document.createElement('div');
        this.viewportElement.className = "carousel__viewport";
        this.controlsElement = document.createElement('div');
        this.controlsElement.className = "carousel__controls row mobile-row center";

        this.keyListener = this.keyHandler.bind(this);
        this.touchStartListener = this.touchStartHandler.bind(this);
        this.touchEndListener = this.touchEndHandler.bind(this);
        this.touchMoveListener = this.touchMoveHandler.bind(this);
        this.resizeListener = this.resizeHandler.bind(this);

        this.constructOptions();
    }

    init() {
        if (!this.hasInit) {
            this.railElement.insertAdjacentElement('beforebegin', this.viewportElement);
            this.railElement.classList.add('row');
            this.railElement.classList.add('mobile-row');
            this.viewportElement.appendChild(this.railElement);
            this.viewportElement.insertAdjacentElement('afterend', this.controlsElement);
            window.addEventListener("keydown", this.keyListener);
            window.addEventListener("resize", this.resizeListener);
            this.viewportElement.addEventListener("touchstart", this.touchStartListener,  { passive: true });
            this.viewportElement.addEventListener("touchend", this.touchEndListener, { passive: true });
            this.viewportElement.addEventListener("touchmove", this.touchMoveListener, { passive: true });
        }

        this.controlsElement.innerHTML = null;

        this.sizeItems();
        this.constructPager();
        this.constructArrows();

        if (!this.hasInit) {
            this.setVisibleElements();

            if (this.options.onMove) {
                this.options.onMove(this.visibleElements, this.index);
            }

            if (this.options.auto) {
                this.setupAuto();
            }

            this.hasInit = true;
        }

        if (this.index > 0) {
            const moveAmt = this.getMoveAmt();
            const items = [].slice.call(this.railElement.children) as HTMLElement[];
            const firstActive = parseInt(items[0].dataset.rwdxCarouselIndex);

            let retargetIndex = Math.floor(firstActive / moveAmt);
            this.index = 0;
            this.reorderItems();
            this.move(null, retargetIndex);
        }
    }

    private constructOptions() {
        const { dataset } = this.railElement;

        this.options = {
            speed: parseInt(dataset.rwdxCarouselSpeed ?? "600"),
            auto: dataset.rwdxCarouselAuto === "true",
            autoPause: parseInt(dataset.rwdxCarouselAutoPause ?? "5000"),
            visible: parseInt(dataset.rwdxCarouselVisible ?? "1"),
            move: parseInt(dataset.rwdxCarouselMove ?? "1"),
            gap: parseFloat(dataset.rwdxCarouselGap ?? "0"),
        }

        if (dataset.rwdxCarouselVisibleBreakpoints) {
            const breakpoints = dataset.rwdxCarouselVisibleBreakpoints.split(";");
            this.options.visibleBreakPoints = breakpoints.map(breakpoint => {
                const split = breakpoint.split(":");
                return {
                    breakpoint: parseInt(split[0]),
                    visible: parseInt(split[1])
                }
            })
        }

        if (dataset.rwdxCarouselMoveBreakpoints) {
            const breakpoints = dataset.rwdxCarouselMoveBreakpoints.split(";");
            this.options.moveBreakpoints = breakpoints.map(breakpoint => {
                const split = breakpoint.split(":");
                return {
                    breakpoint: parseInt(split[0]),
                    move: parseInt(split[1])
                }
            })
        }

        if (dataset.rwdxCarouselOnMove) {
            this.options.onMove = carouselMoveListeners[dataset.rwdxCarouselOnMove];
        }
    }

    private sizeItems() {
        this.visible = this.getVisible();
        const totalItems = this.railElement.children.length;
        const items = [].slice.call(this.railElement.children) as HTMLElement[];
        const rootFontSize = getRootFontSize();

        const marginAmt = (this.options.gap * rootFontSize) * ((totalItems - 1) / totalItems);
        const railSize = this.viewportElement.clientWidth * totalItems / this.visible;
        this.railElement.style.width = railSize + "px";
        this.railElement.style.gap = `0 ${this.options.gap}rem`;

        this.itemSize = this.viewportElement.clientWidth / this.visible;

        for (let i = 0; i <  items.length; i++) {
            if (!this.hasInit) {
                items[i].setAttribute('data-rwdx-carousel-index', i.toString());
            }

            items[i].style.width = this.itemSize - marginAmt + "px";
            items[i].style.flex = `0 0 ${this.itemSize - marginAmt}px`;
        }
    }

    private reorderItems() {
        const items = this.railElement.children;
        const sorted = [...items].sort((a : HTMLElement,b : HTMLElement) => {
            if (parseInt(a.dataset.rwdxCarouselIndex) < parseInt(b.dataset.rwdxCarouselIndex)) {
                return -1;
            }
            else return 1;
        });
        this.railElement.innerHTML = "";
        for (const item of sorted) { this.railElement.appendChild(item); }

    }

    private constructPager() {
        const moveAmt = this.getMoveAmt();
        this.pagerElement = document.createElement('div');
        this.pagerElement.className = "carousel__pager row mobile-row center";

        const finalIndex = this.railElement.children.length / moveAmt;

        for (let i = 0; i < finalIndex; i++) {
            const dotElement = document.createElement('button');
            dotElement.className = "carousel__dot";
            dotElement.setAttribute('data-rwdx-carousel-index', i.toString());

            if (i === this.index)
                dotElement.setAttribute('aria-current', 'true');

            dotElement.addEventListener("click", () => {
                clearInterval(this.autoInterval);
                this.move(null, i);
            });

            this.pagerElement.appendChild(dotElement);
        }

        this.controlsElement.appendChild(this.pagerElement);
    }

    private constructArrows() {
        const prevArrowElement = document.createElement('button');
        const nextArrowElement = document.createElement('button');
        prevArrowElement.className = "carousel__arrow carousel__arrow--prev ui-hover";
        nextArrowElement.className = "carousel__arrow carousel__arrow--next ui-hover";

        prevArrowElement.addEventListener("click", () => {
            clearInterval(this.autoInterval);
            this.options.auto = false;
            this.move(-1);
        });

        nextArrowElement.addEventListener("click", () => {
            clearInterval(this.autoInterval);
            this.options.auto = false;
            this.move(1);
        });

        this.controlsElement.insertAdjacentElement("afterbegin", prevArrowElement);
        this.controlsElement.insertAdjacentElement("beforeend", nextArrowElement)
    }

    move(direction: -1|1|null, index?: number) {
        if (this.moving) return;

        this.moving = true;
        this.railElement.setAttribute('data-moving', 'true');

        let moveAmt = this.getMoveAmt()
        if (moveAmt > this.visible) moveAmt = this.visible;

        const { speed } = this.options;
        const items = this.railElement.children;
        const originalLength = items.length;

        if (!isNaN(index)) {
            moveAmt = Math.abs(index * moveAmt - this.index * moveAmt);
            direction = index > this.index ? 1 : -1;
        } else {
            const currentItem = this.index * moveAmt;
            let finalIndex = Math.floor(items.length / moveAmt);
            if (moveAmt === 1) finalIndex = items.length - 1;
            const destination = currentItem + (direction * moveAmt);
            const finalItem = items.length - 1;

            if (destination > finalItem) moveAmt = (finalItem + 1) - currentItem;
            if (destination < 0) moveAmt = (finalItem + 1) - (finalIndex * moveAmt);
        }

        const offset = direction === -1 ? moveAmt * this.itemSize : 0;

        this.createClones(direction, moveAmt);

        const amt = this.itemSize * moveAmt * direction;

        this.railElement.style.marginLeft = -offset + "px";
        this.railElement.style.transition = `translate ${speed}ms linear`;
        this.railElement.style.translate = `${-amt}px 0`;

        clearTimeout(this.moveTimeout);
        this.moveTimeout = setTimeout(() => {
            this.settle(originalLength, moveAmt, direction, index);
        }, speed);
    }

    private settle(
        originalLength: number,
        moveAmt: number,
        direction: number,
        index?: number
    ) {
        const normalMoveAmt = this.getMoveAmt();
        const items = this.railElement.children;
        let finalIndex = Math.floor(originalLength / normalMoveAmt) - 1;
        if (normalMoveAmt === 1) finalIndex = originalLength - 1;

        this.railElement.removeAttribute('data-moving');
        this.railElement.style.marginLeft = "0";
        this.railElement.style.translate = '0 0';
        this.railElement.style.transition = 'none';

        this.index = index ?? this.index + direction;
        if (this.index > finalIndex) this.index = 0;
        if (this.index < 0) this.index = finalIndex;

        this.deleteClones();

        const itemsToMove : Element[] = [];

        for (let i = 0; i < moveAmt; i++) {
            if (direction === -1) itemsToMove.push(items[items.length - 1 - i]);
            if (direction === 1) itemsToMove.push(items[i]);
        }

        for (const item of itemsToMove) {
            if (direction === -1)
                this.railElement.insertAdjacentElement('afterbegin', item)

            if (direction === 1)
                this.railElement.appendChild(item)
        }

        this.setVisibleElements();

        if (this.options.onMove) {
            this.options.onMove(this.visibleElements, this.index);
        }

        this.moving = false;
        this.railElement.setAttribute('data-moving', 'true');
    }

    private setVisibleElements() {
        this.visibleElements = [];
        const items = this.railElement.children;

        for (let i = 0; i < items.length; i++) {
            const isVisible = i < this.visible;
            items[i].setAttribute('aria-current', isVisible.toString());
            if (isVisible) this.visibleElements.push(items[i] as HTMLElement);
        }

        for (const dot of this.pagerElement.children) {
            const dotIndex = parseInt(dot.getAttribute('data-rwdx-carousel-index'));
            dot.setAttribute('aria-current', (dotIndex === this.index).toString());
        }
    }

    private getMoveAmt() : number {
        let { move, moveBreakpoints } = this.options;

        if (moveBreakpoints) {
            const sortedBreakpoints = this.sortBreakpoints(moveBreakpoints);
            for (const sortedBreakpoint of sortedBreakpoints) {
                if (sortedBreakpoint.breakpoint >= window.innerWidth) {
                    move = sortedBreakpoint.move;
                    break;
                }
            }
        }

        if (move > this.visible) move = this.visible;
        return move;
    }

    private getVisible() : number {
        let { visible } = this.options;
        let { visibleBreakPoints } = this.options;

        if (visibleBreakPoints) {
            const sortedBreakpoints = this.sortBreakpoints(visibleBreakPoints);
            for (const sortedBreakpoint of sortedBreakpoints) {
                if (sortedBreakpoint.breakpoint >= window.innerWidth) {
                    visible = sortedBreakpoint.visible;
                    break;
                }
            }
        }

        return visible;
    }

    private sortBreakpoints(breakpoints : CarouselBreakpoint[]) : CarouselBreakpoint[] {
        return [...breakpoints]
            .sort((a, b) => {
                if (a.breakpoint < b.breakpoint) return -1;
                if (a.breakpoint > b.breakpoint) return 1;
                return 0;
            });
    }

    private createClones(direction: number, cloneAmount: number) {
        if (this.hasClones) return;

        const items = this.railElement.children;
        const startClones : Element[] = [];
        const endClones : Element[] = [];

        for (let i = 0; i < cloneAmount; i++) {
            if (direction === -1 || direction === 0)
                startClones.push(items[items.length - 1 - i].cloneNode(true) as Element);

            if (direction === 1 || direction === 0)
                endClones.push(items[i].cloneNode(true) as Element);
        }

        for (const clone of startClones) {
            clone.setAttribute('data-rwdx-carousel-clone', 'true');
            this.railElement.insertAdjacentElement('afterbegin', clone);
        }

        for (const clone of endClones) {
            clone.setAttribute('data-rwdx-carousel-clone', 'true');
            this.railElement.appendChild(clone);
        }

        this.hasClones = true;
    }

    private deleteClones() {
        if (!this.hasClones) return;

        const items = this.railElement.children;
        for (let i = items.length - 1; i >= 0; i--) {
            if (items[i].hasAttribute('data-rwdx-carousel-clone'))
                this.railElement.removeChild(items[i]);
        }

        this.hasClones = false;
    }

    private keyHandler(e: KeyboardEvent) {
        const bounds = this.viewportElement.getBoundingClientRect();
        if (bounds.top < -(bounds.height * 0.5) && bounds.bottom > window.innerHeight) return;

        switch (e.code) {
            case "KeyA":
            case "ArrowLeft":
                clearInterval(this.autoInterval);
                this.move(-1);
                break;

            case "KeyD":
            case "ArrowRight":
                clearInterval(this.autoInterval);
                this.move(1);
                break;
        }
    }

    private touchStartHandler(e: TouchEvent) {
        this.touchStartX = e.touches[0].clientX;
        this.touchFrame = requestAnimationFrame(this.touchFrameHandler.bind(this));

        this.railElement.style.marginLeft = `-${this.itemSize * this.visible}px`;
        this.createClones(0, this.visible);
    }

    private touchEndHandler() {
        this.touchStartX = null;
        this.touchMoveX = null;
        cancelAnimationFrame(this.touchFrame);

        if (!this.moving) {
            clearTimeout(this.moveTimeout);
            this.railElement.style.transition = 'translate 150ms ease-out';
            this.railElement.style.translate = `0 0`;

            this.moveTimeout = setTimeout(() => {
                this.railElement.style.marginLeft = `0`;
                this.railElement.style.transition = 'translate 0 ease-out';
                this.deleteClones();
            }, 150);
        }
    }

    private touchMoveHandler(e: TouchEvent) {
        this.touchMoveX = e.touches[0].clientX - this.touchStartX;
    }

    private touchFrameHandler () {
        this.touchFrame = requestAnimationFrame(this.touchFrameHandler.bind(this));
        const threshold = (this.itemSize * this.visible) * 0.5;

        if (this.touchMoveX === null) return;

        this.railElement.style.translate = `${this.touchMoveX}px 0`;

        if (Math.abs(this.touchMoveX) > threshold) {
            this.deleteClones();
            this.railElement.style.marginLeft = `0`;
            this.move(this.touchMoveX < 0 ? 1 : -1);
            this.touchEndHandler();
            clearInterval(this.autoInterval);
        }
    }

    private resizeHandler() {
        clearTimeout(this.resizeTimeout);
        this.resizeTimeout = setTimeout(this.init.bind(this), 50);
    }

    private setupAuto() {
        this.autoInterval = setInterval(() => {
            this.move(1)
        }, this.options.autoPause);
    }

    override cleanup() {
        clearInterval(this.moveTimeout);
        window.removeEventListener("keydown", this.keyListener);
        window.removeEventListener("resize", this.resizeListener);
        this.viewportElement.removeEventListener("touchstart", this.touchStartListener);
        this.viewportElement.removeEventListener("touchend", this.touchEndListener);
        this.viewportElement.removeEventListener("touchmove", this.touchMoveListener);
    }
}

export default RWDXCarousel;