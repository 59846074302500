const textAnimation = () => {
    const words = ['Affordable', 'Custom', 'Professional'];
    const wordElement = document.getElementById('highlighted-word');
    let iterator = 0;

    setTimeout(() => {
        wordElement.classList.add('backspace');
    }, 2300);

    const replaceWord = () => {
        wordElement.classList.remove('backspace');
        wordElement.classList.add('rehighlight');
        wordElement.innerHTML = words[iterator];

        if (window.screen.width < 980) wordElement.style.animation = 'typed-reveal 300ms linear forwards';

        setTimeout(() => {
            if (window.screen.width < 980) wordElement.style.animation = ''
            wordElement.classList.add('backspace')
            wordElement.classList.remove('rehighlight');
        }, 2300)

        iterator++;

        if (iterator > words.length - 1) iterator = 0;
    };

    setInterval(replaceWord, 3000);
}

window.scrollY;

export default textAnimation;